/* eslint-disable no-undef, react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'

import { routes } from '../utils'
import { Page, Flex, Box } from '../elements'
import { MainCatLink } from '../components'
import { Translations } from '../containers'

const IndexPage = ({ data: { images } }) => {
  const imgs = {}
  images.edges.forEach(({ node }) => {
    const key = node.childImageSharp.fluid.originalName.split('.')[0]
    imgs[key] = node.childImageSharp
  })

  return (
    <Translations.Consumer>
      {({ t }) => (
        <Page
          {...{
            // justifyContent: `center`,
            // alignItems: `center`,
            pb: 0,
          }}
        >
          <Flex
            {...{
              // justifyContent: `stretch`,
              // alignItems: `center`,
              // flexWrap: `wrap`,
              flexDirection: 'column',
              width: '100%',
              // maxWidth: '600px',
              // css: css`
              //   & > div {
              //     width: 100px;
              //   }
              // `,
            }}
          >
            {Object.entries(routes.index).map(([name, link]) => (
              <MainCatLink
                {...{
                  key: name,
                  icon: name,
                  text: t(name),
                  to: link,
                  color: name,
                }}
              />
            ))}
          </Flex>
        </Page>
      )}
    </Translations.Consumer>
  )
}
// <BubbleLink
//   {...{
//     key: route,
//     image: imgs[route[0]],
//     to: route[1],
//     text: t(route[0]),
//     color: route[0],
//   }}
// />

// TODO:
export const pageQuery = graphql`
  query IndexQuery {
    images: allFile(filter: { relativePath: { regex: "/bubbles//" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 200) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default IndexPage
